import React, { useRef } from 'react'
import useLazyImg from '../hooks/use-lazy-img'

const onLoad = event => event.target.classList.remove('lazy')

export default function LazyImg ({ src, alt, style }) {
  const imgRef = useRef()
  useLazyImg(imgRef)

  return (
    <img
      ref={imgRef}
      className='u-lazyImg lazy'
      data-src={src}
      alt={alt}
      onLoad={onLoad}
      style={style}
    />
  )
}
