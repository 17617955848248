import getScrollTop from 'usfl/dom/get-scroll-top'
import { useEffect, useState } from 'react'

export default function useIsScrolled () {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const onScroll = () => setScrolled(getScrollTop() > 2)

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [setScrolled])

  return scrolled
}
