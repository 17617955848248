import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Logo from './logo'
import Menu from './menu'
import { toggleMobileMenuOpen } from '../actions/ui'

export default function Header ({ siteTitle }) {
  const dispatch = useDispatch()

  const mobileMenuOpen = useSelector(state => state.ui.mobileMenuOpen)

  const onBurgerButton = () => dispatch(toggleMobileMenuOpen())

  return (
    <>
      <div className='Header-title u-padH' role='banner'>
        <Logo />
      </div>
      <nav className='Header-nav Header-navDesktop u-noPrint'>
        <Menu className='Menu--header' menuId='desktop' />
      </nav>
      <button className={`Header-burger u-noPrint ${mobileMenuOpen ? 'is-open' : ''}`} onClick={onBurgerButton}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </>
  )
}
