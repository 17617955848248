import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useSiteMetadata from './use-site-metadata'
import { geolocate, mockGeolocate } from '../actions/app'
import qs from 'qs'

export default function useGeolocation () {
    const siteMetadata = useSiteMetadata()
    const dispatch = useDispatch()

    useEffect(() => {
      if (typeof location !== 'undefined') {
        const params = qs.parse(location.search.slice(1))
        if (params.country) {
          dispatch(mockGeolocate(params.country.toUpperCase()))
          return
        }
      }
      dispatch(geolocate(siteMetadata.siteURL))
    }, [])
}