import React from 'react'
import { useSelector } from 'react-redux'
import Menu from './menu'

export default function MobileMenu ({ siteTitle }) {
  const mobileMenuOpen = useSelector(state => state.ui.mobileMenuOpen)

  return (
    <div className={`MobileMenu ${mobileMenuOpen ? 'is-open' : ''}`}>
      <div className='MobileMenu-content'>
        <div className='MobileMenu-inner u-menu'>
          <article className='MobileMenu-menu'>
            <Menu className='Menu--mobile' menuId='mobile' />
          </article>
        </div>
      </div>
    </div>
  )
}
