import React from 'react'
import Svg from './svg'
import useOptions from '../hooks/use-options'

const twitterSvg = `<svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><title>icons/socials/twitter</title><path d="M15.052 29.832c8.263 0 14.962-6.678 14.962-14.916S23.315 0 15.052 0C6.788 0 .089 6.678.089 14.916s6.699 14.916 14.963 14.916zm8.937-20.615a7.23 7.23 0 0 1-2.111.591 3.75 3.75 0 0 0 1.616-2.076c-.71.43-1.497.743-2.334.91a3.636 3.636 0 0 0-2.683-1.184c-2.03 0-3.675 1.68-3.675 3.752 0 .294.032.58.095.855-3.055-.157-5.763-1.65-7.576-3.92a3.794 3.794 0 0 0-.497 1.886c0 1.301.648 2.45 1.635 3.123a3.604 3.604 0 0 1-1.665-.47v.048c0 1.817 1.266 3.334 2.948 3.678a3.613 3.613 0 0 1-1.66.065c.468 1.49 1.825 2.575 3.434 2.605a7.28 7.28 0 0 1-5.442 1.554 10.25 10.25 0 0 0 5.634 1.685c6.76 0 10.458-5.717 10.458-10.674 0-.163-.004-.325-.011-.486a7.558 7.558 0 0 0 1.834-1.942z" fill-rule="evenodd"/></svg>`

const facebookSvg = `<svg width="31" height="30" viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg"><title>icons/socials/facebook</title><path d="M15.865 29.832c8.264 0 14.963-6.678 14.963-14.916S24.129 0 15.865 0C7.602 0 .903 6.678.903 14.916s6.699 14.916 14.962 14.916zm1.059-6v-8.49h2.859l.428-3.308h-3.287V9.922c0-.958.266-1.611 1.645-1.611h1.758V5.35c-.304-.04-1.348-.13-2.562-.13-2.534 0-4.27 1.541-4.27 4.373v2.44H10.63v3.308h2.866v8.49h3.429z" fill-rule="evenodd"/></svg>`

const instagramSvg = `<svg width="31" height="30" viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg"><title>icons/socials/instagram</title><g fill-rule="evenodd"><path d="M15.68 29.832c8.263 0 14.962-6.678 14.962-14.916S23.943 0 15.679 0C7.416 0 .717 6.678.717 14.916s6.699 14.916 14.962 14.916zm7.828-20.26a2.13 2.13 0 0 0-2.131-2.13H10.289a2.13 2.13 0 0 0-2.13 2.13V20.66a2.13 2.13 0 0 0 2.13 2.13h11.088a2.13 2.13 0 0 0 2.13-2.13V9.573z"/><path d="M15.844 12.115a2.983 2.983 0 1 1 0 5.965 2.983 2.983 0 0 1 0-5.965z"/><path d="M21.775 20.661c0 .236-.205.398-.44.398h-11.08c-.236 0-.412-.162-.412-.398v-6.82h1.483a4.689 4.689 0 1 0 9.037 0h1.412v6.82zM21.775 11.689a.41.41 0 0 1-.413.42h-2.131a.433.433 0 0 1-.439-.42V9.558c0-.236.204-.432.44-.432h2.13a.42.42 0 0 1 .413.432v2.13z"/></g></svg>`

const youtubeSvg = `<svg width="31" height="30" viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg"><title>icons/socials/youtube</title><g fill-rule="evenodd"><path d="M15.637 29.832c8.263 0 14.962-6.678 14.962-14.916S23.9 0 15.637 0C7.373 0 .674 6.678.674 14.916s6.7 14.916 14.963 14.916zm8.789-18.895s-.176-1.241-.714-1.788c-.682-.717-1.447-.72-1.798-.763-2.511-.182-6.278-.182-6.278-.182h-.008s-3.767 0-6.278.182c-.35.042-1.115.046-1.798.763-.538.547-.713 1.788-.713 1.788s-.18 1.457-.18 2.915v1.367c0 1.457.18 2.915.18 2.915s.175 1.241.713 1.788c.683.717 1.58.694 1.979.77 1.435.138 6.101.18 6.101.18s3.77-.005 6.282-.188c.35-.041 1.116-.045 1.798-.762.538-.547.714-1.788.714-1.788s.179-1.458.179-2.915v-1.367c0-1.458-.18-2.915-.18-2.915z"/><path d="M13.78 16.874l-.001-5.06 4.849 2.539-4.849 2.521z"/></g></svg>`

const Link = ({ className, href, children }) => (
  <a className={className} href={href} target='_blank' rel='noopener noreferrer'>
    {children}
  </a>
)

export default function Social ({ className, menuId, children, modifier }) {
  const options = useOptions()
  const {
    twitterLink,
    facebookLink,
    instagramLink,
    youtubeLink
  } = options.general

  return (
    <nav className={`Social ${modifier || ''}`}>
      <ul className='Social-list'>
        {twitterLink && (
          <li className='Social-item'>
            <Link className='Social-link' href={twitterLink}>
              <Svg>{twitterSvg}</Svg>
            </Link>
          </li>
        )}
        {facebookLink && (
          <li className='Social-item'>
            <Link className='Social-link' href={facebookLink}>
              <Svg>{facebookSvg}</Svg>
            </Link>
          </li>
        )}
        {instagramLink && (
          <li className='Social-item'>
            <Link className='Social-link' href={instagramLink}>
              <Svg>{instagramSvg}</Svg>
            </Link>
          </li>
        )}
        {youtubeLink && (
          <li className='Social-item'>
            <Link className='Social-link' href={youtubeLink}>
              <Svg>{youtubeSvg}</Svg>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}
