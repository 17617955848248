import setStyle from 'usfl/dom/set-style'
import Particle from 'usfl/particle'
import lerp from 'usfl/math/lerp'
import logoA0 from './images/logo-particles/logo_a_0.png'
import logoA1 from './images/logo-particles/logo_a_1.png'
import logoA2 from './images/logo-particles/logo_a_2.png'
import logoA3 from './images/logo-particles/logo_a_3.png'
import logoA4 from './images/logo-particles/logo_a_4.png'
import logoB0 from './images/logo-particles/logo_b_0.png'
import logoB1 from './images/logo-particles/logo_b_1.png'
import logoB2 from './images/logo-particles/logo_b_2.png'
import logoB3 from './images/logo-particles/logo_b_3.png'
import logoB4 from './images/logo-particles/logo_b_4.png'

const lettersAll = [{
  l: 'p',
  x: 0,
  y: 0,
  px: 9,
  py: 9
}, {
  l: 'r',
  x: 25,
  y: 0,
  px: 32,
  py: 12
}, {
  l: 'i',
  x: 51,
  y: 0,
  px: 55,
  py: 12
}, {
  l: 'm',
  x: 63,
  y: 0,
  px: 78,
  py: 16
}, {
  l: 'o',
  x: 94,
  y: 0,
  px: 108,
  py: 12
}]

const assets = {
  logoA0,
  logoA1,
  logoA2,
  logoA3,
  logoA4,
  logoB0,
  logoB1,
  logoB2,
  logoB3,
  logoB4
}

const opts = {
  friction: 0.98,
  frictionOut: 1,
  maxSpeed: 1,
  thrust: 0.6,
  minDistance: 20,
  returnForce: 0.006,
  thrustMultiplier: 0.5
}

function initLogo (el) {
  const point = { x: 0, y: 0 }

  const letters = lettersAll

  const assetMap = Object.keys(assets).reduce((ob, key) => {
    ob[key] = document.createElement('img')
    ob[key].src = assets[key]
    return ob
  }, {})

  function onLoad () {
    el.classList.add('is-loaded')

    const particles = letters.map((l, i) => {
      const div = document.createElement('div')
      const img = document.createElement('div')
      div.appendChild(img)

      const imgA = assetMap[`logoA${i}`]
      const imgB = assetMap[`logoB${i}`]
      img.appendChild(imgA)
      img.appendChild(imgB)

      const { width, height } = imgA
      const x = l.x + width * 0.25
      const y = l.y + height * 0.25
      const margin = { x: 32, y: 18 }

      return new Particle(Object.assign({}, l, {
        x,
        y,
        div,
        img,
        width,
        height,
        friction: opts.friction,
        bounds: {
          x: -margin.x,
          y: -margin.y,
          width: 120 + margin.x * 2,
          height: 25 + margin.y * 2
        },
        bounce: { x: -1.1, y: -1.1 },
        angle: 0,
        point: { x: l.px, y: l.py },
        isOver: false,
        timeoutId: null
      }))
    })

    particles.forEach((p) => {
      const div = p.opts.div
      el.appendChild(div)
      setStyle(div, {
        left: `${p.x}px`,
        top: `${p.y}px`
      })
    })

    let timeoutId = null

    function onOut () {
      window.clearTimeout(timeoutId)
      for (let i = 0; i < particles.length; i++) {
        particles[i].opts.isOver = false
      }
    }

    function onMove (event) {
      event.preventDefault()
      event.stopImmediatePropagation()

      const touch = event.touches && event.touches.length

      if (touch) {
        el.removeEventListener('mousemove', onMove)
      }

      const { left, top } = el.getBoundingClientRect()
      const pointer = touch ? event.touches[0] : event
      point.x = pointer.clientX - left
      point.y = pointer.clientY - top

      for (let i = 0; i < particles.length; i++) {
        const p = particles[i]
        const distance = p.distanceTo(point)
        p.opts.isOver = distance < opts.minDistance
        if (p.opts.isOver) {
          const angle = p.angleTo(point) - Math.PI
          const thrust = (1 - distance / opts.minDistance) * opts.thrustMultiplier
          p.accellerate(thrust, angle)
        }
      }

      window.clearTimeout(timeoutId)
      timeoutId = window.setTimeout(() => onOut(), 2000)
    }
    el.addEventListener('mousemove', onMove)
    el.addEventListener('touchmove', onMove)

    function update () {
      window.requestAnimationFrame(update)

      for (let i = 0; i < particles.length; i++) {
        const p = particles[i]

        if (p.opts.isOver && p.speed > opts.maxSpeed) {
          p.speed = opts.maxSpeed
        }

        if (!p.opts.isOver) {
          p.friction = opts.frictionOut
          p.moveTo(p.opts, opts.returnForce)
        } else {
          p.friction = opts.friction
        }

        p.update()
        p.edgeCollide()

        setStyle(p.opts.div, {
          left: `${p.x}px`,
          top: `${p.y}px`
        })
        p.opts.angle = p.opts.isOver ? lerp(p.opts.angle, p.angle, 0.05) : lerp(p.opts.angle, 0, 0.1)
        setStyle(p.opts.img, {
          transform: `rotate(${p.opts.angle}rad)`
        })
      }
    }
    update()

    return () => {
      el.removeEventListener('mousemove', onMove)
      el.removeEventListener('touchmove', onMove)
    }
  }

  const checkLoaded = () => {
    const isLoaded = Object.keys(assetMap).every(key => assetMap[key].width > 0)
    if (isLoaded) {
      onLoad()
    } else {
      window.requestAnimationFrame(checkLoaded)
    }
  }

  checkLoaded()
}

export default function logoParticles (containerRef) {
  return initLogo(containerRef.current)
}
