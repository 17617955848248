import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'gatsby'
import { dismissBanner } from '../actions/ui'
import Html from './html'

export default function Banner ({ data }) {
  const {
    siteBanner,
    siteBannerColour,
    siteBannerContent,
    siteBannerLink
  } = data

  const dispatch = useDispatch()

  const onClose = () => dispatch(dismissBanner())

  if (!siteBanner) {
    return null
  }

  const isInternalLink = siteBannerLink && siteBannerLink.slice(0, 1) === '/'

  const small = siteBannerContent.length > 90

  return (
    <section className={`Banner ${siteBannerColour}`}>
      <Html className={`Page-content Page-content--inverted ${small ? 'Banner--small' : ''}`}>
        {siteBannerContent}
      </Html>
      <button
        className='Button Button--close no-hover'
        type='button'
        onClick={onClose}
      >
        close
      </button>
      {siteBannerLink && !isInternalLink && (
        <a className='u-blockLink' href={siteBannerLink}>
          {siteBannerLink}
        </a>
      )}
      {siteBannerLink && isInternalLink && (
        <Link className='u-blockLink' to={siteBannerLink}>
          {siteBannerLink}
        </Link>
      )}
    </section>
  )
}
