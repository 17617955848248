import React from 'react'
import { useDispatch } from 'react-redux'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { toggleMobileMenuOpen } from '../actions/ui'

function isExternal(url) {
  return url.indexOf('mailto:') === 0 || url.indexOf('https:') === 0 || url.indexOf('http:') === 0
}

export default function Menu ({ className, menuId, children, childClassName }) {
  const dispatch = useDispatch()
  const { wpgraphql } = useStaticQuery(
    graphql`
      query MenuQuery {
        wpgraphql {
          menus {
            edges {
              node {
                slug
                menuItems {
                  edges {
                    node {
                      label
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const menu = wpgraphql.menus.edges.find(edge => edge.node.slug === menuId)

  if (!menu) {
    console.error(`Menu ${menuId} not found`)
    return null
  }

  const onClick = () => {
    if (menuId === 'mobile') {
      setTimeout(() => {
        dispatch(toggleMobileMenuOpen(false))
      }, 50)
    }
  }

  return (
    <ul className={`Menu ${className || ''}`}>
      {menu.node.menuItems.edges.map(({ node }) => {
        // const shopButton = menuId === 'desktop' && node.url.includes('shop')
        const linkTo = node.url
          .replace('https://solidlabs.wpengine.com', '')
          .replace('https://www.primotoys.com', '')
          .replace('https://primotoys.com', '')
          .replace('http://localhost:8000', '')

        return (
          <li key={node.label} className={childClassName}>
            {isExternal(linkTo) ? (
              <a
                className='u-extendHitarea'
                href={node.url}
              >
                {node.label}
              </a>
            ) : (
              <Link
                className='u-extendHitarea'
                activeClassName='is-current'
                partiallyActive
                to={linkTo}
                onClick={onClick}
              >
                {node.label}
              </Link>
            )}
          </li>
        )
      })}
      {children}
    </ul>
  )
}
