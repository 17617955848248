import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { graphql, useStaticQuery } from 'gatsby'
import LazyImg from './lazy-img'
import Html from './html'
import { acknowledgeCookieNotice } from '../actions/app'

function Notice () {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query CookieNoticeQuery {
        wpgraphql {
          options {
            general {
              cookieNotice
              cookieImage {
                mediaItemUrl
              }
            }
          }
        }
      }
    `
  )

  const {
    cookieNotice,
    cookieImage
  } = wpgraphql.options.general

  const dispatch = useDispatch()
  const onClose = () => dispatch(acknowledgeCookieNotice())

  return (
    <section className='Cookie-notice'>
      <div className='Cookie-container'>
        <div className='Cookie-close'>
          <button className='Button Button--close no-hover' onClick={onClose}>close</button>
        </div>
         <div className='Cookie-content'>
          <LazyImg
            src={cookieImage.mediaItemUrl}
            alt='cookie'
          />
          <Html>{cookieNotice}</Html>
        </div>
      </div>
    </section>
  )
}

export default function CookieNotice () {
  const acknowledged = useSelector(state => state.app.cookieNoticeAcknowledged)

  if (typeof document === 'undefined' || acknowledged) {
    return null
  }

  return (
    <Notice />
  )
}
