import { useStaticQuery, graphql } from 'gatsby'

export default function useOptions () {
  const { wpgraphql } = useStaticQuery(
    graphql`
    query OptionsQuery {
      wpgraphql {
        options {
          distributors {
            distributorsImage {
              mediaItemUrl
              altText
            }
            distributorsMessage
            noDistributorsMessage
            distributorsViewAllButtonLabel
            distributors {
              country
              url
              buyButtonLabel
            }
          }
          general {
            footerAddress
            footerCopyrightName
            footerSecurityMessage
            enableNewsletterSignup
            twitterLink
            youtubeLink
            facebookLink
            instagramLink
            socialShareImage {
              mediaItemUrl
            }
            siteBanner
            siteBannerColour
            siteBannerContent
            siteBannerLink
            cookieImage {
              mediaItemUrl
            }
            cookieNotice
            image404 {
              mediaItemUrl
            }
            newsletterTitle
            newsletterDescription
          }
          store {
            productPageDeliveryText
            recommendationsTitle
            buyButtonLabel
            learnMoreLabel
            discoverCubettoLabel
            childImageText
            lockImageText
            rosetteImageText
          }
        }
      }
    }
    `
  )

  return wpgraphql.options
}
