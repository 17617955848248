import '../utils/polyfills'
import React from 'react'
import { useSelector } from 'react-redux'
import Header from './header'
import MobileMenu from './mobile-menu'
import Footer from './footer'
import Seo from './seo'
import CookieNotice from './cookie-notice'
import Banner from './banner'
import Distro from './distro'
import useIsScrolled from '../hooks/use-is-scrolled'
import useOptions from '../hooks/use-options'
import useGeolocation from '../hooks/use-geolocation'

const templatesWithHero = ['playroom', 'education', 'distributors', 'ebook', 'story', 'stockists']

function Wrapper ({ children, template, options, bannerDismissed }) {
  let mainClassName = ''

  const scrolled = useIsScrolled()

  const mobileMenuOpen = useSelector(state => state.ui.mobileMenuOpen)

  if (templatesWithHero.includes(template)) {
    mainClassName = `has-hero ${scrolled ? 'is-scrolled' : ''} ${mobileMenuOpen ? 'has-mobile-menu' : ''}`
  }

  if (!bannerDismissed && options.general.siteBanner) {
    mainClassName += ` has-banner`
  }

  return (
    <div className={`SiteWrapper ${mainClassName} ${template}`}>
      {children}
    </div>
  )
}

export default function Layout ({ children, page, template, path }) {
  const { seo, slug, featuredImage } = page
  const image = featuredImage && featuredImage.mediaItemUrl
  const options = useOptions()
  const bannerDismissed = useSelector(state => state.ui.bannerDismissed)

  useGeolocation()

  return (
    <>
      <Seo {...seo} image={image} path={path || `/${slug}/`} />
      <Wrapper template={template} options={options} bannerDismissed={bannerDismissed}>
        <Banner data={options.general} />
        <MobileMenu />
        <header className='Header u-padH' data-header>
      		<Header />
      	</header>
      	<main>
      		{children}
      	</main>
        <CookieNotice />
      	<footer className='Footer u-padH u-shadow u-noPrint'>
      		<Footer />
      	</footer>
        <Distro />
      </Wrapper>
    </>
  )
}
