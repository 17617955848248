import { useEffect } from 'react'

let imageObserver

function setSrc (image) {
  image.src = image.dataset.src
  // image.classList.remove('lazy')
  image.removeAttribute('data-src')
}

function onChange (entries, observer) {
  entries.forEach(function (entry) {
    if (entry.isIntersecting) {
      const image = entry.target
      imageObserver.unobserve(image)
      setSrc(image)
    }
  })
}

export default function useLazyImg (imageRef) {
  useEffect(() => {
    if (imageRef.current && imageRef.current.src) {
      return () => {}
    }
    if (window.IntersectionObserver) {
      if (!imageObserver) {
        imageObserver = new window.IntersectionObserver(onChange)
      }
      imageObserver.observe(imageRef.current)
    } else {
      setSrc(imageRef.current)
    }

    return () => {
      if (imageObserver) {
        imageObserver.unobserve(imageRef.current)
      }
    }
  }, [imageRef.current])
}
