import { useStaticQuery, graphql } from 'gatsby'

export default function useSiteMetadata () {
  const { site } = useStaticQuery(
    graphql`
    query SiteMetadataQuery {
      site {
        siteMetadata {
          siteURL
          title
          description
          author
          wpEndpoint
          wpAuth
        }
      }
    }
    `
  )

  return site.siteMetadata
}
