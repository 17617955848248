import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import useSiteMetadata from '../hooks/use-site-metadata'

export default function SEO({ title, metaDesc, image, path, noIndex, additional }) {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query GeneralOptionsQuery {
        wpgraphql {
          options {
            general {
              socialShareImage {
                mediaItemUrl
              }
              twitterLink
              facebookLink
              instagramLink
              youtubeLink
            }
          }
        }
      }
    `
  )

  const {
    socialShareImage,
    twitterLink,
    facebookLink,
    instagramLink,
    youtubeLink
  } = wpgraphql.options.general

  const siteMetadata = useSiteMetadata()
  const seoTitle = (title || siteMetadata.title).replace(/&amp;/g, '&')
  const metaDescription = metaDesc || siteMetadata.description
  const imgUrl = image || socialShareImage.mediaItemUrl

  return (
    <Helmet>
      <html lang='en' />
      <title>{seoTitle}</title>
      <meta name='description' content={metaDescription} />
      {noIndex ? (
        <meta name='robots' content='noindex, follow' />
      ) : (
        <link rel='canonical' href={`${siteMetadata.siteURL}${path}`} />
      )}
      <meta property="og:locale" content="en_GB" />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={seoTitle} />
      <meta property='og:description' content={metaDescription} />
      <meta property="og:url" content={`${siteMetadata.siteURL}${path}`} />
      <meta property="og:site_name" content="Primo Toys" />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:image:secure_url" content={imgUrl} />
      <meta property="og:image:width" content="1024" />
      <meta property="og:image:height" content="683" />

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:description' content={metaDescription} />
      <meta name='twitter:title' content={seoTitle} />
      <meta name="twitter:site" content="@primotoys" />
      <meta name="twitter:image" content={imgUrl} />
      <meta name='twitter:creator' content={siteMetadata.author} />

      <script type='application/ld+json'>{`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "${siteMetadata.siteURL}",
        "sameAs": [
          "${twitterLink}",
          "${facebookLink}",
          "${instagramLink}",
          "${youtubeLink}"
        ],
        "@id": "https://www.primotoys.com/#organization",
        "name": "Primo Toys",
        "logo": "https://www.primotoys.com/wp-content/uploads/2017/09/PrimoToys.png"
      }
      `}</script>

      <meta name="msvalidate.01" content="4D85E25F9E690BCB7E824001DA62D1EF" />
      <meta name="google-site-verification" content="Yk7YINnCJpHMFMcB4EDNPvOSXI3oCRuWDcAL4tQu3j0" />
      <meta name="yandex-verification" content="3472a9fc9fc24c12" />

      <link rel='sitemap' type='application/xml' href='/sitemap.xml' />

      {additional}
    </Helmet>
  )
}
