import { useSelector } from 'react-redux'
import useOptions from '../hooks/use-options'

export default function useDistro () {
  const { distributors: { distributors } } = useOptions()

  let country = useSelector(state => state.app.geolocation)

  const distro = distributors.filter(d => d.country === country)

  return distro
}
