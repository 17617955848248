import React from 'react'
import Logo from './logo'
import Menu from './menu'
import Svg from './svg'
import Social from './social'
import useOptions from '../hooks/use-options'

const lockSvg = `<svg width='12' height='15' viewBox='0 0 12 15' xmlns='http://www.w3.org/2000/svg'><title>Lock</title><path d='M3.291 4.732c0-1.4 1.235-2.537 2.743-2.537 1.508 0 2.743 1.138 2.743 2.537v2.237H3.291V4.732zm7.183 2.237h-.325V4.732c0-2.155-1.85-3.909-4.115-3.909C3.77.823 1.92 2.577 1.92 4.732v2.237h-.325C.733 6.969 0 7.68 0 8.542v4.298c0 .862.733 1.535 1.595 1.535h8.879A1.51 1.51 0 0 0 12 12.84V8.542c0-.862-.664-1.573-1.526-1.573z' fill='#FAF5EB' fill-rule='evenodd'/></svg>`

export default function Footer () {
  const { general: { footerAddress, footerCopyrightName, footerSecurityMessage } } = useOptions()
  return (
    <section className='Footer-content'>
      <div className='Footer-title'>
        <Logo footer />
      </div>
      <nav className='Footer-nav'>
        <Menu className='Menu--footer' menuId='footer' />
      </nav>
      {footerSecurityMessage && (
        <ul className='Footer-row'>
          <li className='Footer-item Footer-secure'>
            <Svg>{lockSvg}</Svg>
            <span>{footerSecurityMessage}</span>
          </li>
        </ul>
      )}
      <ul className='Footer-row Footer-last'>
          <Menu className='Footer-legal' childClassName='Footer-item' menuId='legal'>
            <>
              <li className='Footer-item Footer-copyright'>© {new Date().getFullYear()} {footerCopyrightName}</li>
              <li className='Footer-item Footer-address'>
                <address>
                  {footerAddress}
                </address>
              </li>
            </>
          </Menu>
          <li className='Footer-item Footer-social'>
            <Social />
          </li>
      </ul>
  </section>
  )
}
