import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'gatsby'
import Html from './html'
import LazyImg from './lazy-img'
import useOptions from '../hooks/use-options'
import useDistro from '../hooks/use-distro'
import { openDistro } from '../actions/ui'

export default function Distro () {
  const dispatch = useDispatch()
  const open = useSelector(state => state.ui.distroOpen)
  const noop = event => event.stopPropagation()

  const handleClose = () => {
    dispatch(openDistro(false))
  }

  const { distributors: {
    distributorsImage,
    distributorsMessage,
    noDistributorsMessage,
    distributorsViewAllButtonLabel,
  } } = useOptions()

  const distros = useDistro()
  const message = distros.length > 0 ? distributorsMessage : noDistributorsMessage

  return (
    <article className={`Overlay u-absCenterChild ${open ? 'is-visible' : 'is-hidden'}`} onClick={handleClose}>
      <div className='Overlay-inner u-padH'>
        <div className='Overlay-content u-centered' onClick={noop}>
          <div className='Overlay-close'>
            <button className='Button Button--close' onClick={handleClose}>
              close
            </button>
          </div>
          <div className='Overlay-image u-centered u-marginBottom--md'>
            <LazyImg
              src={distributorsImage.mediaItemUrl}
              alt={distributorsImage.altText}
            />
          </div>
          <Html className='Page-content u-centered u-max-md u-marginBottom--md'>
            {message}
          </Html>
          {distros.map(item => {
            return (
              <p style={{ marginBottom: '16px' }} key={item.url}>
                <a
                    className='Button--underline'
                    href={item.url}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    {item.buyButtonLabel}
                </a>
              </p>
            )
          })}
          <Link className='Button--underline' to='/stockists' style={{ marginTop: '32px' }} onClick={handleClose}>
              {distributorsViewAllButtonLabel || 'View all stockists'}
          </Link>
        </div>
      </div>
    </article>
  )
}
